import {
	IMAGE_BANK_LIST_THEMES_BEGIN,
	IMAGE_BANK_LIST_THEMES_SUCCESS,
	IMAGE_BANK_LIST_THEMES_FAILURE,
	IMAGE_BANK_CREATE_THEME_BEGIN,
	IMAGE_BANK_CREATE_THEME_SUCCESS,
	IMAGE_BANK_CREATE_THEME_FAILURE,
	IMAGE_BANK_DELETE_THEME_BEGIN,
	IMAGE_BANK_DELETE_THEME_SUCCESS,
	IMAGE_BANK_DELETE_THEME_FAILURE,
	IMAGE_BANK_EDIT_THEME_BEGIN,
	IMAGE_BANK_EDIT_THEME_SUCCESS,
	IMAGE_BANK_EDIT_THEME_FAILURE,
	IMAGE_BANK_LOAD_THEME_BEGIN,
	IMAGE_BANK_LOAD_THEME_SUCCESS,
	IMAGE_BANK_LOAD_THEME_FAILURE,
} from "../types/ImageBankTypes";

const initialState = {
	loading: false,
	theme: null,
	themes: null,
	error: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case IMAGE_BANK_LIST_THEMES_BEGIN:
		case IMAGE_BANK_DELETE_THEME_BEGIN:
		case IMAGE_BANK_CREATE_THEME_BEGIN:
		case IMAGE_BANK_EDIT_THEME_BEGIN:
		case IMAGE_BANK_LOAD_THEME_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
			};
		case IMAGE_BANK_LIST_THEMES_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				themes: action.payload,
			};
		case IMAGE_BANK_CREATE_THEME_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				theme: action.payload,
			};
		case IMAGE_BANK_DELETE_THEME_SUCCESS:
			const newThemes = state.themes["hydra:member"].filter(
				(theme) => theme["@id"] !== action.payload
			);

			return {
				...state,
				loading: false,
				error: null,
				themes: {
					...state.themes,
					["hydra:member"]: newThemes,
					["hydra:totalItems"]: newThemes.length,
				},
			};
		case IMAGE_BANK_EDIT_THEME_SUCCESS:
			const arrayOfThemes = [];

			state.themes["hydra:member"].map((item) => {
				if (item["@id"] === action.theme["@id"]) {
					arrayOfThemes.push(action.payload);
				} else {
					arrayOfThemes.push(item);
				}
			});

			return {
				...state,
				loading: false,
				error: null,
				themes: {
					...state.themes,
					["hydra:member"]: arrayOfThemes,
				},
			};
		case IMAGE_BANK_LOAD_THEME_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				theme: action.payload,
			};
		case IMAGE_BANK_LIST_THEMES_FAILURE:
		case IMAGE_BANK_CREATE_THEME_FAILURE:
		case IMAGE_BANK_DELETE_THEME_FAILURE:
		case IMAGE_BANK_EDIT_THEME_FAILURE:
		case IMAGE_BANK_LOAD_THEME_FAILURE:
			return {
				...state,
				loading: false,
				error: true,
			};
		default:
			return state;
	}
};
