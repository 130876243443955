import {
	DATASET_CREATE_BEGIN,
	DATASET_CREATE_SUCCESS,
	DATASET_CREATE_FAILURE,
	DATASET_LOAD_BEGIN,
	DATASET_LOAD_SUCCESS,
	DATASET_LOAD_FAILURE,
	DATASET_LIST_BEGIN,
	DATASET_LIST_SUCCESS,
	DATASET_LIST_FAILURE,
	DATASET_REMOVE_BEGIN,
	DATASET_REMOVE_SUCCESS,
	DATASET_REMOVE_FAILURE,
	DATASET_EDIT_BEGIN,
	DATASET_EDIT_SUCCESS,
	DATASET_EDIT_FAILURE,
} from "../types/DatasetTypes";

const initialState = {
	loading: false,
	dataset: null,
	error: null,
	datasets: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case DATASET_LOAD_BEGIN:
		case DATASET_CREATE_BEGIN:
		case DATASET_LIST_BEGIN:
		case DATASET_REMOVE_BEGIN:
		case DATASET_EDIT_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
			};
		case DATASET_CREATE_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				dataset: action.payload,
			};

		case DATASET_LOAD_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				dataset: action.payload,
			};
		case DATASET_REMOVE_SUCCESS:
			const newDatasetList = state.datasets["hydra:member"].filter(
				(dataset) => dataset["@id"] !== action.payload
			);

			return {
				...state,
				loading: false,
				error: null,
				datasets: {
					...state.datasets,
					["hydra:member"]: newDatasetList,
				},
			};
		case DATASET_EDIT_SUCCESS:
			if (state.datasets && state.datasets["hydra:member"].length !== 0) {
				const arrayFromDatasets = [];

				state.datasets["hydra:member"].map((item) => {
					if (item["@id"] === action.dataset["@id"]) {
						arrayFromDatasets.push(action.payload);
					} else {
						arrayFromDatasets.push(item);
					}
				});

				return {
					...state,
					loading: false,
					error: null,
					datasets: {
						...state.datasets,
						["hydra:member"]: arrayFromDatasets,
					},
					dataset: action.payload,
				};
			} else {
				return {
					...state,
					loading: false,
					error: null,
					dataset: action.payload,
				};
			}

		case DATASET_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				datasets: action.payload,
			};
		case DATASET_CREATE_FAILURE:
		case DATASET_LOAD_FAILURE:
		case DATASET_LIST_FAILURE:
		case DATASET_REMOVE_FAILURE:
		case DATASET_EDIT_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};
