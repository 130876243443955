import React from "react";
import * as Icon from "react-feather";

// Beware: to make the permissions works, a user must only have ONE role
// The list of navigation's permissions must include the list of all role that have access
// I.e : "ROLE_USER", "ROLE_ADMIN"

const NavigationConfig = [
	{
		id: "action.home",
		title: "action.home",
		type: "item",
		icon: <Icon.Home size={20} />,
		permissions: ["ROLE_STUDENT", "ROLE_PARENT", "ROLE_TEACHER", "ROLE_ADMIN"],
		navLink: "home",
	},
	{
		id: "action.games",
		title: "action.games",
		type: "item",
		icon: <Icon.Dribbble size={20} />,
		permissions: ["ROLE_TEACHER", "ROLE_ADMIN"],
		navLink: "account.game.home",
	},
	{
		id: "actions.datasets",
		title: "action.datasets",
		type: "item",
		icon: <Icon.Database size={20} />,
		permissions: ["ROLE_TEACHER", "ROLE_ADMIN"],
		navLink: "account.dataset.home",
	},
	{
		id: "admin.action.home",
		title: "admin.action.home",
		type: "collapse",
		icon: <Icon.Lock size={20} />,
		permissions: ["ROLE_ADMIN"],
		children: [
			{
				id: "admin.action.users",
				title: "admin.action.users",
				type: "item",
				icon: <Icon.Users size={10} />,
				navLink: "admin.users.home",
				permissions: ["ROLE_ADMIN"],
			},

			{
				id: "admin.action.settings.home",
				title: "admin.action.settings.home",
				type: "collapse",
				icon: <Icon.Settings size={10} />,
				permissions: ["ROLE_ADMIN"],
				children: [
					{
						id: "admin.action.settings.school",
						title: "admin.action.settings.school",
						type: "item",
						icon: <Icon.Circle size={10} />,
						navLink: "admin.settings.school",
						permissions: ["ROLE_ADMIN"],
					},
					{
						id: "admin.action.settings.statuses",
						title: "admin.action.settings.statuses",
						type: "item",
						icon: <Icon.Circle size={10} />,
						navLink: "admin.settings.statuses",
						permissions: ["ROLE_ADMIN"],
					},
					{
						id: "admin.action.settings.image_bank",
						title: "admin.action.settings.image_bank",
						type: "item",
						icon: <Icon.Circle size={10} />,
						navLink: "admin.settings.image_bank",
						permissions: ["ROLE_ADMIN"],
					},
				],
			},
		],
	},
];

export default NavigationConfig;
