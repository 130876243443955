// Create
export const EDUCATION_SUBJECT_CATEGORY_CREATE_BEGIN =
	"EDUCATION_SUBJECT_CATEGORY_CREATE_BEGIN";
export const EDUCATION_SUBJECT_CATEGORY_CREATE_SUCCESS =
	"EDUCATION_SUBJECT_CATEGORY_CREATE_SUCCESS";
export const EDUCATION_SUBJECT_CATEGORY_CREATE_FAILURE =
	"EDUCATION_SUBJECT_CATEGORY_CREATE_FAILURE";

// Load
export const EDUCATION_SUBJECT_CATEGORY_LOAD_BEGIN =
	"EDUCATION_SUBJECT_CATEGORY_LOAD_BEGIN";
export const EDUCATION_SUBJECT_CATEGORY_LOAD_SUCCESS =
	"EDUCATION_SUBJECT_CATEGORY_LOAD_SUCCESS";
export const EDUCATION_SUBJECT_CATEGORY_LOAD_FAILURE =
	"EDUCATION_SUBJECT_CATEGORY_LOAD_FAILURE";

// List
export const EDUCATION_SUBJECT_CATEGORY_LIST_BEGIN =
	"EDUCATION_SUBJECT_CATEGORY_LIST_BEGIN";
export const EDUCATION_SUBJECT_CATEGORY_LIST_SUCCESS =
	"EDUCATION_SUBJECT_CATEGORY_LIST_SUCCESS";
export const EDUCATION_SUBJECT_CATEGORY_LIST_FAILURE =
	"EDUCATION_SUBJECT_CATEGORY_LIST_FAILURE";

// Remove
export const EDUCATION_SUBJECT_CATEGORY_REMOVE_BEGIN =
	"EDUCATION_SUBJECT_CATEGORY_REMOVE_BEGIN";
export const EDUCATION_SUBJECT_CATEGORY_REMOVE_SUCCESS =
	"EDUCATION_SUBJECT_CATEGORY_REMOVE_SUCCESS";
export const EDUCATION_SUBJECT_CATEGORY_REMOVE_FAILURE =
	"EDUCATION_SUBJECT_CATEGORY_REMOVE_FAILURE";

// Edit
export const EDUCATION_SUBJECT_CATEGORY_EDIT_BEGIN =
	"EDUCATION_SUBJECT_CATEGORY_EDIT_BEGIN";
export const EDUCATION_SUBJECT_CATEGORY_EDIT_SUCCESS =
	"EDUCATION_SUBJECT_CATEGORY_EDIT_SUCCESS";
export const EDUCATION_SUBJECT_CATEGORY_EDIT_FAILURE =
	"EDUCATION_SUBJECT_CATEGORY_EDIT_FAILURE";
