import {
	CONTACT_MESSAGE_CREATE_BEGIN,
	CONTACT_MESSAGE_CREATE_SUCCESS,
	CONTACT_MESSAGE_CREATE_FAILURE,
} from "../types/ContactMessageTypes";

const initialState = {
	loading: false,
	success: false,
	error: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CONTACT_MESSAGE_CREATE_BEGIN:
			return {
				...state,
				loading: true,
				success: false,
				error: null,
			};
		case CONTACT_MESSAGE_CREATE_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				success: true,
			};

		case CONTACT_MESSAGE_CREATE_FAILURE:
			return {
				...state,
				loading: false,
				success: false,
				error: false,
			};
		default:
			return state;
	}
};
