import {
	REGISTRATION_BEGIN,
	REGISTRATION_SUCCESS,
	REGISTRATION_FAILURE,
	CONFIRMATION_BEGIN,
	CONFIRMATION_SUCCESS,
	CONFIRMATION_FAILURE,
} from "../types/RegistrationTypes";

const initialState = {
	loading: false,
	error: null,
	isConfirmed: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case REGISTRATION_BEGIN:
		case CONFIRMATION_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
			};
		case REGISTRATION_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
			};
		case REGISTRATION_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		case CONFIRMATION_FAILURE:
			return {
				...state,
				loading: false,
				error: true,
			};

		case CONFIRMATION_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				isConfirmed: true,
			};
		default:
			return state;
	}
};
