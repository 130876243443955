import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import classnames from "classnames";
import { ChevronRight } from "react-feather";

import { makeHOC } from "../../../../../Utility/makeHOC";
import { useRouteTranslation } from "../../../../../Utility/useRouteTranslation";
import { withTranslation } from "react-i18next";

const injectRouteTranslation = makeHOC(
	useRouteTranslation,
	"useRouteTranslation"
);

class SideMenuGroup extends React.Component {
	constructor(props) {
		super(props);
		this.flag = true;
		this.parentArray = [];
		this.childObj = {};
	}
	state = {
		isOpen: false,
		activeItem: this.props.activePath,
	};

	handleActiveItem = (url) => {
		this.setState({
			activeItem: url,
		});
	};

	componentDidUpdate(prevProps, prevState) {
		const { tr } = this.props.useRouteTranslation;
		const { t } = this.props;

		if (prevProps.activePath !== this.props.activePath) {
			if (this.childObj.navLink && this.childObj.collapsed) {
				this.props.collapsedMenuPaths(this.childObj.navLink);
			}
			if (
				this.props.activePath === tr(this.childObj.navLink) &&
				!this.props.parentArr.includes(this.parentArray[0])
			) {
				this.props.parentArr.splice(0, this.props.parentArr.length);
				this.props.parentArr.push(this.parentArray);
			} else if (this.props.parentArr.includes(this.parentArray)) {
				this.props.parentArr.splice(0, this.props.parentArr.length);
			}
		}
	}

	renderChild(item, activeGroup, handleGroupClick, handleActiveItem, parent) {
		const { tr } = this.props.useRouteTranslation;
		const { t } = this.props;

		return (
			<ul className='menu-content'>
				{item.children
					? item.children.map((child) => {
							const CustomAnchorTag =
								child.type === "external-link" ? `a` : Link;
							if (!this.parentArray.includes(item.id) && this.flag) {
								this.parentArray.push(item.id);
							}

							if (child.navlink && child.collapsed) {
								this.props.collapsedMenuPaths(child.navLink);
							}

							if (this.props.activeItemState === tr(child.navLink)) {
								this.childObj = child;
								this.props.parentArr.push(this.parentArray);
								this.flag = false;
							}
							if (
								(child.permissions &&
									child.permissions.some(
										(e) => this.props.currentUser.indexOf(e) >= 0
									)) ||
								child.permissions === undefined
							) {
								return (
									<li
										key={child.id}
										className={classnames({
											hover: this.props.hoverIndex === child.id,
											"has-sub": child.type === "collapse",
											open:
												child.type === "collapse" &&
												activeGroup.includes(child.id),
											"sidebar-group-active": this.props.currentActiveGroup.includes(
												child.id
											),
											active:
												(this.props.activeItemState === tr(child.navLink) &&
													child.type === "item") ||
												(item.parentOf &&
													item.parentOf.includes(this.props.activeItemState)),
											disabled: child.disabled,
										})}
										onClick={(e) => {
											e.stopPropagation();
											handleGroupClick(child.id, item.id, child.type);
											if (
												tr(child.navLink) &&
												tr(child.navLink) !== undefined
											) {
												handleActiveItem(tr(child.navLink));
											}
											if (
												this.props.deviceWidth <= 1200 &&
												child.type === "item"
											) {
												this.props.toggleMenu();
											}
										}}
									>
										<CustomAnchorTag
											className={classnames({
												"d-flex justify-content-between":
													child.type === "collapse",
											})}
											to={
												tr(child.navLink) && child.type === "item"
													? tr(child.navLink)
													: ""
											}
											href={
												child.type === "external-link" ? tr(child.navLink) : ""
											}
											onMouseEnter={() => {
												this.props.handleSidebarMouseEnter(child.id);
											}}
											onMouseLeave={() => {
												this.props.handleSidebarMouseEnter(child.id);
											}}
											key={child.id}
											onClick={(e) => {
												return child.type === "collapse"
													? e.preventDefault()
													: "";
											}}
											target={child.newTab ? "_blank" : undefined}
										>
											<div className='menu-text'>
												{child.icon}
												<span className='menu-item menu-title'>
													{t(child.title)}
												</span>
											</div>
											{child.badge ? (
												<Badge
													color={child.badge}
													className='float-right mr-2'
													pill
												>
													{child.badgeText}
												</Badge>
											) : (
												""
											)}
											{child.type === "collapse" ? (
												<ChevronRight className='menu-toggle-icon' size={13} />
											) : (
												""
											)}
										</CustomAnchorTag>

										{child.children
											? this.renderChild(
													child,
													activeGroup,
													handleGroupClick,
													handleActiveItem,
													item.id
											  )
											: ""}
									</li>
								);
							} else if (
								tr(child.navLink) === this.props.activePath &&
								!child.permissions.includes(this.props.currentUser)
							) {
								return this.props.redirectUnauthorized();
							} else {
								return null;
							}
					  })
					: null}
			</ul>
		);
	}

	render() {
		return (
			<React.Fragment>
				{this.renderChild(
					this.props.group,
					this.props.activeGroup,
					this.props.handleGroupClick,
					this.props.handleActiveItem,
					null
				)}
			</React.Fragment>
		);
	}
}
export default injectRouteTranslation(withTranslation()(SideMenuGroup));
