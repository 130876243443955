export function makeHOC(useHook, name) {
	return function (Component) {
		const HOC = function (props) {
			const hookData = useHook(props);
			const hookProps = { [name]: hookData };
			return <Component {...props} {...hookProps} />;
		};

		HOC.displayName = `${name}HOC`;

		return HOC;
	};
}

// export function makeRenderProps(useHook, name) {
//     const RenderProps = function ({ children, ...rest }) {
//       const hookData = useHook(rest);
//       return children(hookData);
//     };

//     if (name) RenderProps.displayName = `${name}RenderProps`;

//     return RenderProps;
//   }

//   const TodosData = makeRenderProps(useTodos, "Todos")
