// Create
export const DATASET_CREATE_BEGIN = "DATASET_CREATE_BEGIN";
export const DATASET_CREATE_SUCCESS = "DATASET_CREATE_SUCCESS";
export const DATASET_CREATE_FAILURE = "DATASET_CREATE_FAILURE";

// Load
export const DATASET_LOAD_BEGIN = "DATASET_LOAD_BEGIN";
export const DATASET_LOAD_SUCCESS = "DATASET_LOAD_SUCCESS";
export const DATASET_LOAD_FAILURE = "DATASET_LOAD_FAILURE";

// List
export const DATASET_LIST_BEGIN = "DATASET_LIST_BEGIN";
export const DATASET_LIST_SUCCESS = "DATASET_LIST_SUCCESS";
export const DATASET_LIST_FAILURE = "DATASET_LIST_FAILURE";

// Remove
export const DATASET_REMOVE_BEGIN = "DATASET_REMOVE_BEGIN";
export const DATASET_REMOVE_SUCCESS = "DATASET_REMOVE_SUCCESS";
export const DATASET_REMOVE_FAILURE = "DATASET_REMOVE_FAILURE";

// Edit
export const DATASET_EDIT_BEGIN = "DATASET_EDIT_BEGIN";
export const DATASET_EDIT_SUCCESS = "DATASET_EDIT_SUCCESS";
export const DATASET_EDIT_FAILURE = "DATASET_EDIT_FAILURE";
