// export const BACK_URL = "https://localhost:8000";
// export const FRONT_URL = "http://localhost:3000";

export const BACK_URL = "https://kairos-backend.edunew.fr";
export const FRONT_URL = "https://kairos-site.edunew.fr";

export const SITE_NAME = "EduNew";

export const ITEMS_PER_PAGE = 30;

export const MEDIA_BASEPATH = BACK_URL;
export const MEDIA_AVATAR_PATH = "/media/avatar/";
export const MEDIA_DATA_PATH = "/media/data/";
export const MEDIA_CONTENT_PATH = "/media/content/";
export const MEDIA_IMAGE_BANK_PATH = "/media/image_bank/";

export const DATASET_TYPES = [
	{ value: "equivalence", label: "dataset.type.equivalence" },
];
export const EQUIVALENCE_TYPES = [
	{ value: "text", label: "equivalence.type.text" },
	{ value: "image", label: "equivalence.type.image" },
];

export const BOT_MIN_TIMEOUT_TICTACTOE = 1000; //ms
export const BOT_MAX_TIMEOUT_TICTACTOE = 2500; //ms

export const ADDRESS_STREET = "Boulevard Blanqui";
export const ADDRESS_ZIPCODE = "70013";
export const ADDRESS_TOWN = "Paris";
export const ADDRESS_COUNTRY = "France";

export const EMAIL_ADDRESS = "contact@edunew.fr";
