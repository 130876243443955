import {
	COUNTRY_CREATE_BEGIN,
	COUNTRY_CREATE_SUCCESS,
	COUNTRY_CREATE_FAILURE,
	COUNTRY_LOAD_BEGIN,
	COUNTRY_LOAD_SUCCESS,
	COUNTRY_LOAD_FAILURE,
	COUNTRY_LIST_BEGIN,
	COUNTRY_LIST_SUCCESS,
	COUNTRY_LIST_FAILURE,
	COUNTRY_REMOVE_BEGIN,
	COUNTRY_REMOVE_SUCCESS,
	COUNTRY_REMOVE_FAILURE,
} from "../types/CountryTypes";

// Countries
import listOfCountries from "iso3166-2-db/countryList/fr";

const initialState = {
	loading: false,
	country: null,
	error: null,
	countries: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case COUNTRY_CREATE_BEGIN:
		case COUNTRY_LOAD_BEGIN:
		case COUNTRY_LIST_BEGIN:
		case COUNTRY_REMOVE_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
			};
		case COUNTRY_REMOVE_SUCCESS:
			const newCountryList = state.countries["hydra:member"].filter(
				(country) => country["@id"] !== action.payload
			);

			return {
				...state,
				loading: false,
				error: null,
				countries: {
					...state.countries,
					["hydra:member"]: newCountryList,
				},
			};
		case COUNTRY_CREATE_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				country: action.payload,
			};
		case COUNTRY_LOAD_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				country: action.payload,
			};
		case COUNTRY_LIST_SUCCESS:
			let test = action.payload["hydra:member"].sort((a, b) =>
				listOfCountries[a.countryCode].name <
				listOfCountries[b.countryCode].name
					? -1
					: listOfCountries[a.countryCode].name >
					  listOfCountries[b.countryCode].name
					? 1
					: 0
			);

			let modifiedPayload = {
				...action.payload,
				"hydra:member": test,
			};

			return {
				...state,
				loading: false,
				error: null,
				countries: modifiedPayload,
			};
		case COUNTRY_CREATE_FAILURE:
		case COUNTRY_LOAD_FAILURE:
		case COUNTRY_LIST_FAILURE:
		case COUNTRY_REMOVE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};
