import {
	EDUCATION_SUBJECT_CATEGORY_CREATE_BEGIN,
	EDUCATION_SUBJECT_CATEGORY_CREATE_SUCCESS,
	EDUCATION_SUBJECT_CATEGORY_CREATE_FAILURE,
	EDUCATION_SUBJECT_CATEGORY_LOAD_BEGIN,
	EDUCATION_SUBJECT_CATEGORY_LOAD_SUCCESS,
	EDUCATION_SUBJECT_CATEGORY_LOAD_FAILURE,
	EDUCATION_SUBJECT_CATEGORY_LIST_BEGIN,
	EDUCATION_SUBJECT_CATEGORY_LIST_SUCCESS,
	EDUCATION_SUBJECT_CATEGORY_LIST_FAILURE,
	EDUCATION_SUBJECT_CATEGORY_REMOVE_BEGIN,
	EDUCATION_SUBJECT_CATEGORY_REMOVE_SUCCESS,
	EDUCATION_SUBJECT_CATEGORY_REMOVE_FAILURE,
	EDUCATION_SUBJECT_CATEGORY_EDIT_BEGIN,
	EDUCATION_SUBJECT_CATEGORY_EDIT_SUCCESS,
	EDUCATION_SUBJECT_CATEGORY_EDIT_FAILURE,
} from "../types/EducationSubjectCategoryTypes";

const initialState = {
	loading: false,
	category: null,
	error: null,
	categories: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case EDUCATION_SUBJECT_CATEGORY_CREATE_BEGIN:
		case EDUCATION_SUBJECT_CATEGORY_LOAD_BEGIN:
		case EDUCATION_SUBJECT_CATEGORY_LIST_BEGIN:
		case EDUCATION_SUBJECT_CATEGORY_REMOVE_BEGIN:
		case EDUCATION_SUBJECT_CATEGORY_EDIT_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
			};
		case EDUCATION_SUBJECT_CATEGORY_REMOVE_SUCCESS:
			const newCategoriesList = state.categories["hydra:member"].filter(
				(category) => category["@id"] !== action.category
			);

			return {
				...state,
				loading: false,
				error: null,
				categories: {
					...state.categories,
					["hydra:member"]: newCategoriesList,
				},
			};
		case EDUCATION_SUBJECT_CATEGORY_CREATE_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				category: action.payload,
			};
		case EDUCATION_SUBJECT_CATEGORY_EDIT_SUCCESS:
			const arrayFromCategories = [];

			state.categories["hydra:member"].map((item) => {
				if (item["@id"] === action.subject["@id"]) {
					arrayFromCategories.push(action.payload);
				} else {
					arrayFromCategories.push(item);
				}
			});

			return {
				...state,
				loading: false,
				error: null,
				categories: {
					...state.categories,
					["hydra:member"]: arrayFromCategories,
				},
			};
		case EDUCATION_SUBJECT_CATEGORY_LOAD_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				category: action.payload,
			};
		case EDUCATION_SUBJECT_CATEGORY_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				categories: action.payload,
			};
		case EDUCATION_SUBJECT_CATEGORY_CREATE_FAILURE:
		case EDUCATION_SUBJECT_CATEGORY_LOAD_FAILURE:
		case EDUCATION_SUBJECT_CATEGORY_LIST_FAILURE:
		case EDUCATION_SUBJECT_CATEGORY_REMOVE_FAILURE:
		case EDUCATION_SUBJECT_CATEGORY_EDIT_FAILURE:
			return {
				...state,
				loading: false,
				error: true,
				category: null,
				categories: null,
			};
		default:
			return state;
	}
};
