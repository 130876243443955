// Auth
export const AUTH_BEGIN = "AUTH_BEGIN";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";

// Logout
export const LOGOUT_BEGIN = "LOGOUT_BEGIN";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

// Edit
export const PROFILE_EDIT_BEGIN = "PROFILE_EDIT_BEGIN";
export const PROFILE_EDIT_SUCCESS = "PROFILE_EDIT_SUCCESS";
export const PROFILE_EDIT_FAILURE = "PROFILE_EDIT_FAILURE";

// Security edit
export const SECURITY_EDIT_BEGIN = "SECURITY_EDIT_BEGIN";
export const SECURITY_EDIT_SUCCESS = "SECURITY_EDIT_SUCCESS";
export const SECURITY_EDIT_FAILURE = "SECURITY_EDIT_FAILURE";
