import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import Detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
	// Detect the browser language
	.use(Detector)
	// learn more: https://github.com/i18next/i18next-xhr-backend
	.use(Backend)
	// connect with React
	.use(initReactI18next)
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		debug: false,
		ns: ["translation", "route", "breadcrumb"],
		lng: "fr",
		fallbackLng: "fr",
		whitelist: ["en", "fr"],
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		backend: {
			loadPath: window.location.origin + "/locales/{{lng}}/{{ns}}.json",
		},
		detector: {
			order: [
				"navigator",
				"path",
				"querystring",
				"cookie",
				"localStorage",
				"htmlTag",
				"subdomain",
			],
		},
		react: {
			wait: true,
		},
	});

export default i18n;
