// List
export const EQUIVALENCE_LIST_BEGIN = "EQUIVALENCE_LIST_BEGIN";
export const EQUIVALENCE_LIST_SUCCESS = "EQUIVALENCE_LIST_SUCCESS";
export const EQUIVALENCE_LIST_FAILURE = "EQUIVALENCE_LIST_FAILURE";

// Create
export const EQUIVALENCE_CREATE_BEGIN = "EQUIVALENCE_CREATE_BEGIN";
export const EQUIVALENCE_CREATE_SUCCESS = "EQUIVALENCE_CREATE_SUCCESS";
export const EQUIVALENCE_CREATE_FAILURE = "EQUIVALENCE_CREATE_FAILURE";

// Remove
export const EQUIVALENCE_REMOVE_BEGIN = "EQUIVALENCE_REMOVE_BEGIN";
export const EQUIVALENCE_REMOVE_SUCCESS = "EQUIVALENCE_REMOVE_SUCCESS";
export const EQUIVALENCE_REMOVE_FAILURE = "EQUIVALENCE_REMOVE_FAILURE";

// Edit
export const EQUIVALENCE_EDIT_BEGIN = "EQUIVALENCE_EDIT_BEGIN";
export const EQUIVALENCE_EDIT_SUCCESS = "EQUIVALENCE_EDIT_SUCCESS";
export const EQUIVALENCE_EDIT_FAILURE = "EQUIVALENCE_EDIT_FAILURE";
