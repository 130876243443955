import React, { useState } from "react";

import LogoIcon from "../Assets/img/logo/logo_icon.png";
import LogoText from "../Assets/img/logo/logo_name.png";
import LogoFull from "../Assets/img/logo/logo_full.png";

import {
	ADDRESS_STREET,
	ADDRESS_ZIPCODE,
	ADDRESS_TOWN,
	ADDRESS_COUNTRY,
	EMAIL_ADDRESS,
} from "../Config/Parameters";

import { Link } from "react-router-dom";

import * as Icon from "react-feather";

import { useTranslation } from "react-i18next";
import { useRouteTranslation } from "../Utility/useRouteTranslation";

const HorizontalLayout = ({ children }) => {
	const { t } = useTranslation();
	const { tr } = useRouteTranslation();

	const [collapse, setCollapse] = useState(false);

	return (
		<div className='container horizontal'>
			<nav className='navbar navbar-expand-lg navbar-light bg-white'>
				<Link to={tr("home")} className='navbar-brand' href='/'>
					<img
						src={LogoIcon}
						height='40'
						className='d-inline-block align-content-center'
					/>
					<img
						src={LogoText}
						height='40'
						className='d-none d-lg-inline-block ml-4 align-content-center'
					/>
				</Link>
				<button
					className='navbar-toggler'
					type='button'
					onClick={() => setCollapse(!collapse)}
				>
					<Icon.Menu />
				</button>

				<div
					className={`navbar-collapse justify-content-end ${
						collapse ? "d-flex" : "d-none"
					}`}
				>
					<ul className='navbar-nav'>
						<li className='nav-item'>
							<Link to={tr("home")} className='btn btn-navbar'>
								{t("action.home")}
							</Link>
						</li>
						<li className='nav-item'>
							<Link to={tr("contact")} className='btn btn-navbar'>
								{t("action.contact")}
							</Link>
						</li>
						<li className='nav-item'>
							<Link to={tr("login")} className='btn btn-navbar'>
								{t("action.sign_in")}
							</Link>
						</li>
						<li className='nav-item'>
							<Link to={tr("register")} className='btn btn-navbar'>
								{t("action.sign_up")}
							</Link>
						</li>
					</ul>
				</div>
			</nav>

			<div className='wrapper'>{children}</div>

			<footer>
				<div className='row mb-3 px-5'>
					<div className='col-12 col-md-6 mt-2'>
						<div className='row'>
							<div className='col-2'>
								<Icon.MapPin />
							</div>
							<div className='col-10'>
								{ADDRESS_STREET} <br />
								{ADDRESS_ZIPCODE} &nbsp;
								{ADDRESS_TOWN} <br />
								{ADDRESS_COUNTRY} <br />
							</div>
						</div>
					</div>
					<div className='col-12 col-md-6 mt-2'>
						<div className='row'>
							<div className='col-2'>
								<Icon.Mail />
							</div>
							<div className='col-10'>
								<a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a>
							</div>
						</div>
					</div>
				</div>
				<div className='divider'></div>
				<div className='row mt-3 mb-3 px-5'>
					<div className='col-12'>
						<div className='row'>
							<div className='col-sm-12 col-md-3 mb-2 d-flex justify-content-center'>
								<img src={LogoFull} height='50' />
							</div>
							<div className='col-sm-12 col-md-9 d-flex align-items-center justify-content-center'>
								<p>
									COPYRIGHT © {new Date().getFullYear()} - Tout droit réservé.
									Site conçu par <Link to='/'>EduNew</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default HorizontalLayout;
