import {
	STATUS_LIST_BEGIN,
	STATUS_LIST_SUCCESS,
	STATUS_LIST_FAILURE,
	STATUS_LIST_INFO_BEGIN,
	STATUS_LIST_INFO_SUCCESS,
	STATUS_LIST_INFO_FAILURE,
	STATUS_INFO_CREATE_BEGIN,
	STATUS_INFO_CREATE_SUCCESS,
	STATUS_INFO_CREATE_FAILURE,
	STATUS_INFO_REMOVE_BEGIN,
	STATUS_INFO_REMOVE_SUCCESS,
	STATUS_INFO_REMOVE_FAILURE,
	STATUS_INFO_EDIT_BEGIN,
	STATUS_INFO_EDIT_SUCCESS,
	STATUS_INFO_EDIT_FAILURE,
} from "../types/StatusTypes";

const initialState = {
	loading: false,
	statuses: null,
	statusesInfo: null,
	statusInfo: null,
	error: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case STATUS_LIST_INFO_BEGIN:
		case STATUS_LIST_BEGIN:
		case STATUS_INFO_CREATE_BEGIN:
		case STATUS_INFO_REMOVE_BEGIN:
		case STATUS_INFO_EDIT_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
			};
		case STATUS_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				statuses: action.payload,
			};
		case STATUS_LIST_INFO_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				statusesInfo: action.payload,
			};
		case STATUS_INFO_CREATE_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				statusInfo: action.payload,
			};

		case STATUS_INFO_REMOVE_SUCCESS:
			const newStatusesInfo = state.statusesInfo["hydra:member"].filter(
				(statusInfo) => statusInfo["@id"] !== action.payload
			);

			return {
				...state,
				loading: false,
				error: null,
				statusesInfo: {
					...state.statusesInfo,
					["hydra:member"]: newStatusesInfo,
					["hydra:totalItems"]: newStatusesInfo.length,
				},
			};
		case STATUS_INFO_EDIT_SUCCESS:
			const arrayOfStatusInfo = [];

			state.statusesInfo["hydra:member"].map((item) => {
				if (item["@id"] === action.statusInfo["@id"]) {
					arrayOfStatusInfo.push(action.payload);
				} else {
					arrayOfStatusInfo.push(item);
				}
			});

			return {
				...state,
				loading: false,
				error: null,
				statusesInfo: {
					...state.equivalences,
					["hydra:member"]: arrayOfStatusInfo,
				},
			};
		case STATUS_LIST_INFO_FAILURE:
		case STATUS_LIST_FAILURE:
		case STATUS_INFO_CREATE_FAILURE:
		case STATUS_INFO_REMOVE_FAILURE:
		case STATUS_INFO_EDIT_FAILURE:
			return {
				...state,
				loading: false,
				error: true,
			};
		default:
			return state;
	}
};
