import React from "react";
import ScrollToTop from "react-scroll-up";
import { Button } from "reactstrap";
import { Heart, ArrowUp } from "react-feather";
import classnames from "classnames";

const Footer = (props) => {
	let footerTypeArr = ["sticky", "static", "hidden"];
	return (
		<footer
			className={classnames("footer footer-light", {
				"footer-static":
					props.footerType === "static" ||
					!footerTypeArr.includes(props.footerType),
				"d-none": props.footerType === "hidden",
			})}
		>
			<div className='clearfix mx-5'>
				<div className='row p-5'>
					<div className='col-sm-12 col-md-6'>
						<h6>Adresse</h6>
						<p>
							Boulevard Blanqui
							<br />
							75013 PARIS <br />
							France
						</p>
					</div>
					<div className='col-sm-12 col-md-6'>
						<h6>Adresse email</h6>
						<a href='mailto:contact@edunew.fr'>contact@edunew.fr</a>
					</div>
				</div>
			</div>
			<div className='mt-2 clearfix d-flex justify-content-center'>
				<div className='row'>
					<div className='col-12'>
						COPYRIGHT © {new Date().getFullYear()} - EduNew
					</div>
				</div>
			</div>

			{props.hideScrollToTop === false ? (
				<ScrollToTop showUnder={160}>
					<Button color='primary' className='btn-icon scroll-top'>
						<ArrowUp size={15} />
					</Button>
				</ScrollToTop>
			) : null}
		</footer>
	);
};

export default Footer;
