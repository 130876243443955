import {
	EDUCATION_LEVEL_CREATE_BEGIN,
	EDUCATION_LEVEL_CREATE_SUCCESS,
	EDUCATION_LEVEL_CREATE_FAILURE,
	EDUCATION_LEVEL_LOAD_BEGIN,
	EDUCATION_LEVEL_LOAD_SUCCESS,
	EDUCATION_LEVEL_LOAD_FAILURE,
	EDUCATION_LEVEL_LIST_BEGIN,
	EDUCATION_LEVEL_LIST_SUCCESS,
	EDUCATION_LEVEL_LIST_FAILURE,
	EDUCATION_LEVEL_REMOVE_BEGIN,
	EDUCATION_LEVEL_REMOVE_SUCCESS,
	EDUCATION_LEVEL_REMOVE_FAILURE,
	EDUCATION_LEVEL_EDIT_BEGIN,
	EDUCATION_LEVEL_EDIT_SUCCESS,
	EDUCATION_LEVEL_EDIT_FAILURE,
} from "../types/EducationLevelTypes";

const initialState = {
	loading: false,
	level: null,
	error: null,
	levels: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case EDUCATION_LEVEL_CREATE_BEGIN:
		case EDUCATION_LEVEL_LOAD_BEGIN:
		case EDUCATION_LEVEL_LIST_BEGIN:
		case EDUCATION_LEVEL_REMOVE_BEGIN:
		case EDUCATION_LEVEL_EDIT_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
			};
		case EDUCATION_LEVEL_REMOVE_SUCCESS:
			const newLevelsList = state.levels["hydra:member"].filter(
				(level) => level["@id"] !== action.level
			);

			return {
				...state,
				loading: false,
				error: null,
				levels: {
					...state.levels,
					["hydra:member"]: newLevelsList,
				},
			};
		case EDUCATION_LEVEL_CREATE_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				level: action.payload,
			};
		case EDUCATION_LEVEL_EDIT_SUCCESS:
			const arrayFromLevels = [];

			state.levels["hydra:member"].map((item) => {
				if (item["@id"] === action.level["@id"]) {
					arrayFromLevels.push(action.payload);
				} else {
					arrayFromLevels.push(item);
				}
			});

			return {
				...state,
				loading: false,
				error: null,
				levels: {
					...state.levels,
					["hydra:member"]: arrayFromLevels,
				},
			};
		case EDUCATION_LEVEL_LOAD_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				level: action.payload,
			};
		case EDUCATION_LEVEL_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				levels: action.payload,
			};
		case EDUCATION_LEVEL_CREATE_FAILURE:
		case EDUCATION_LEVEL_LOAD_FAILURE:
		case EDUCATION_LEVEL_LIST_FAILURE:
		case EDUCATION_LEVEL_REMOVE_FAILURE:
		case EDUCATION_LEVEL_EDIT_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				level: null,
				levels: null,
			};
		default:
			return state;
	}
};
