import React from "react";
import { Link } from "react-router-dom";
import { Navbar, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import NavbarUser from "./NavbarUser";
import * as Icon from "react-feather";

import Logo from "../../../Assets/img/logo/logo_icon.png";
import BrandText from "../../../Assets/img/logo/logo_name.png";

import { SITE_NAME } from "../../../Config/Parameters";

import { useTranslation } from "react-i18next";
import { useRouteTranslation } from "../../../Utility/useRouteTranslation";

const ThemeNavbar = (props) => {
	const { t } = useTranslation();
	const { tr } = useRouteTranslation();

	const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
	const navbarTypes = ["floating", "static", "sticky", "hidden"];

	return (
		<React.Fragment>
			<div className='content-overlay' />
			<div className='header-navbar-shadow' />
			<Navbar
				className={classnames(
					"header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
					{
						"navbar-light":
							props.navbarColor === "default" ||
							!colorsArr.includes(props.navbarColor),
						"navbar-dark": colorsArr.includes(props.navbarColor),
						"bg-primary":
							props.navbarColor === "primary" && props.navbarType !== "static",
						"bg-danger":
							props.navbarColor === "danger" && props.navbarType !== "static",
						"bg-success":
							props.navbarColor === "success" && props.navbarType !== "static",
						"bg-info":
							props.navbarColor === "info" && props.navbarType !== "static",
						"bg-warning":
							props.navbarColor === "warning" && props.navbarType !== "static",
						"bg-dark":
							props.navbarColor === "dark" && props.navbarType !== "static",
						"d-none": props.navbarType === "hidden" && !props.horizontal,
						"floating-nav":
							(props.navbarType === "floating" && !props.horizontal) ||
							(!navbarTypes.includes(props.navbarType) && !props.horizontal),
						"navbar-static-top":
							props.navbarType === "static" && !props.horizontal,
						"fixed-top": props.navbarType === "sticky" || props.horizontal,
						scrolling: props.horizontal && props.scrolling,
					}
				)}
			>
				<div className='navbar-wrapper'>
					<div className='navbar-container content'>
						<div
							className='navbar-collapse d-flex justify-content-between align-items-center'
							id='navbar-mobile'
						>
							<div className='bookmark-wrapper'>
								<div className='mr-auto float-left bookmark-wrapper d-flex align-items-center'>
									<ul className='navbar-nav d-xl-none'>
										<NavItem className='mobile-menu mr-auto'>
											<NavLink
												className='nav-menu-main menu-toggle hidden-xs is-active'
												onClick={props.sidebarVisibility}
											>
												<Icon.Menu className='ficon' />
											</NavLink>
										</NavItem>
									</ul>
								</div>
							</div>
							{props.horizontal ? (
								<div className='logo d-flex align-items-center'>
									<Link to={tr("home")}>
										<img
											src={Logo}
											height='40'
											className='d-none d-md-inline-block align-content-center'
											alt={SITE_NAME}
										/>
										<img
											src={BrandText}
											height='40'
											className='d-none d-md-inline-block align-content-center ml-1'
											alt={SITE_NAME}
										/>
									</Link>
								</div>
							) : null}

							{!props.currentUser && (
								<ul className='navbar-nav me-auto mb-2 mb-lg-0'>
									<li className='nav-item'>
										<Link to={tr("home")} className='btn btn-navbar mr-2'>
											{t("action.home")}
										</Link>
									</li>
									<li className='nav-item'>
										<Link to={tr("contact")} className='btn btn-navbar mr-2'>
											{t("action.contact")}
										</Link>
									</li>
									<li className='nav-item'>
										<Link to={tr("login")} className='btn btn-navbar mr-2'>
											{t("action.sign_in")}
										</Link>
									</li>
									<li className='nav-item'>
										<Link to={tr("register")} className='btn btn-navbar'>
											{t("action.sign_up")}
										</Link>
									</li>
								</ul>
							)}

							{props.currentUser && (
								<NavbarUser
									handleAppOverlay={props.handleAppOverlay}
									changeCurrentLang={props.changeCurrentLang}
									currentUser={props.currentUser}
								/>
							)}
						</div>
					</div>
				</div>
			</Navbar>
		</React.Fragment>
	);
};

export default ThemeNavbar;
