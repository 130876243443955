import {
	BACK_URL,
	MEDIA_AVATAR_PATH,
	MEDIA_CONTENT_PATH,
	MEDIA_DATA_PATH,
	MEDIA_BASEPATH,
	MEDIA_IMAGE_BANK_PATH,
} from "../Config/Parameters";

export const asset = (slug, type) => {
	let URL;

	switch (type) {
		case "avatar":
			URL = MEDIA_BASEPATH + MEDIA_AVATAR_PATH + slug;
			break;
		case "data":
			URL = MEDIA_BASEPATH + MEDIA_DATA_PATH + slug;
			break;
		case "image_bank":
			URL = MEDIA_BASEPATH + MEDIA_IMAGE_BANK_PATH + slug;
			break;

		default:
			URL = MEDIA_BASEPATH + MEDIA_CONTENT_PATH + slug;
	}

	return URL;
};
