import {
	EDUCATION_SUBJECT_CREATE_BEGIN,
	EDUCATION_SUBJECT_CREATE_SUCCESS,
	EDUCATION_SUBJECT_CREATE_FAILURE,
	EDUCATION_SUBJECT_LOAD_BEGIN,
	EDUCATION_SUBJECT_LOAD_SUCCESS,
	EDUCATION_SUBJECT_LOAD_FAILURE,
	EDUCATION_SUBJECT_LIST_BEGIN,
	EDUCATION_SUBJECT_LIST_SUCCESS,
	EDUCATION_SUBJECT_LIST_FAILURE,
	EDUCATION_SUBJECT_REMOVE_BEGIN,
	EDUCATION_SUBJECT_REMOVE_SUCCESS,
	EDUCATION_SUBJECT_REMOVE_FAILURE,
	EDUCATION_SUBJECT_EDIT_BEGIN,
	EDUCATION_SUBJECT_EDIT_SUCCESS,
	EDUCATION_SUBJECT_EDIT_FAILURE,
} from "../types/EducationSubjectTypes";

const initialState = {
	loading: false,
	subject: null,
	error: null,
	subjects: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case EDUCATION_SUBJECT_CREATE_BEGIN:
		case EDUCATION_SUBJECT_LOAD_BEGIN:
		case EDUCATION_SUBJECT_LIST_BEGIN:
		case EDUCATION_SUBJECT_REMOVE_BEGIN:
		case EDUCATION_SUBJECT_EDIT_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
			};
		case EDUCATION_SUBJECT_REMOVE_SUCCESS:
			const newSubjectsList = state.subjects["hydra:member"].filter(
				(subject) => subject["@id"] !== action.subject
			);

			return {
				...state,
				loading: false,
				error: null,
				subjects: {
					...state.subjects,
					["hydra:member"]: newSubjectsList,
				},
			};
		case EDUCATION_SUBJECT_CREATE_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				subject: action.payload,
			};
		case EDUCATION_SUBJECT_EDIT_SUCCESS:
			const arrayFromSubjects = [];

			state.subjects["hydra:member"].map((item) => {
				if (item["@id"] === action.subject["@id"]) {
					arrayFromSubjects.push(action.payload);
				} else {
					arrayFromSubjects.push(item);
				}
			});

			return {
				...state,
				loading: false,
				error: null,
				subjects: {
					...state.subjects,
					["hydra:member"]: arrayFromSubjects,
				},
			};
		case EDUCATION_SUBJECT_LOAD_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				subject: action.payload,
			};
		case EDUCATION_SUBJECT_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				subjects: action.payload,
			};
		case EDUCATION_SUBJECT_CREATE_FAILURE:
		case EDUCATION_SUBJECT_LOAD_FAILURE:
		case EDUCATION_SUBJECT_LIST_FAILURE:
		case EDUCATION_SUBJECT_REMOVE_FAILURE:
		case EDUCATION_SUBJECT_EDIT_FAILURE:
			return {
				...state,
				loading: false,
				error: true,
				subject: null,
				subjects: null,
			};
		default:
			return state;
	}
};
