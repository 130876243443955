import { combineReducers } from "redux";

import AuthReducer from "./AuthReducer";
import RegistrationReducer from "./RegistrationReducer";
import DatasetReducer from "./DatasetReducer";
import DataReducer from "./DataReducer";
import CustomizerReducer from "./CustomizerReducer";
import CountryReducer from "./CountryReducer";
import EducationLevelReducer from "./EducationLevelReducer";
import EducationSubjectReducer from "./EducationSubjectReducer";
import EducationSubjectCategoryReducer from "./EducationSubjectCategoryReducer";
import EquivalenceReducer from "./EquivalenceReducer";
import AdminUserReducer from "./AdminUserReducer";
import StatusReducer from "./StatusReducer";
import ImageBankReducer from "./ImageBankReducer";
import ContactMessageReducer from "./ContactMessageReducer";

const rootReducer = combineReducers({
	AuthReducer,
	RegistrationReducer,
	DatasetReducer,
	DataReducer,
	CustomizerReducer,
	CountryReducer,
	EducationLevelReducer,
	EducationSubjectReducer,
	EducationSubjectCategoryReducer,
	EquivalenceReducer,
	AdminUserReducer,
	StatusReducer,
	ImageBankReducer,
	ContactMessageReducer,
});

export default rootReducer;
