// Create
export const COUNTRY_CREATE_BEGIN = "COUNTRY_CREATE_BEGIN";
export const COUNTRY_CREATE_SUCCESS = "COUNTRY_CREATE_SUCCESS";
export const COUNTRY_CREATE_FAILURE = "COUNTRY_CREATE_FAILURE";

// Load
export const COUNTRY_LOAD_BEGIN = "COUNTRY_LOAD_BEGIN";
export const COUNTRY_LOAD_SUCCESS = "COUNTRY_LOAD_SUCCESS";
export const COUNTRY_LOAD_FAILURE = "COUNTRY_LOAD_FAILURE";

// List
export const COUNTRY_LIST_BEGIN = "COUNTRY_LIST_BEGIN";
export const COUNTRY_LIST_SUCCESS = "COUNTRY_LIST_SUCCESS";
export const COUNTRY_LIST_FAILURE = "COUNTRY_LIST_FAILURE";

// Remove
export const COUNTRY_REMOVE_BEGIN = "COUNTRY_REMOVE_BEGIN";
export const COUNTRY_REMOVE_SUCCESS = "COUNTRY_REMOVE_SUCCESS";
export const COUNTRY_REMOVE_FAILURE = "COUNTRY_REMOVE_FAILURE";
