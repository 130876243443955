// Translations
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

// Parameters
import { FRONT_URL } from "../Config/Parameters";

export const useRouteTranslation = () => {
	const { options, language } = i18n;
	const { t } = useTranslation();

	return {
		// Translate route --> return the path with :prop at the end
		tr: (path, props) => {
			return language === options.fallbackLng[0]
				? t("route:" + path, props)
				: "/" + language + t("route:" + path, props);
		},

		// Generate route --> return the generated route (:prop being replaced by its value)
		gr: (path, props) => {
			const replacePieces = (route) => {
				for (let key in props) {
					route = route.replace(":" + key, props[key]);
				}

				return route;
			};

			const relative = props.relative !== undefined ? props.relative : true;

			return language === options.fallbackLng[0]
				? (relative ? "" : FRONT_URL) + replacePieces(t("route:" + path))
				: (relative ? "" : FRONT_URL) +
						replacePieces("/" + language + t("route:" + path));
		},
	};
};
