import {
	EQUIVALENCE_CREATE_BEGIN,
	EQUIVALENCE_CREATE_SUCCESS,
	EQUIVALENCE_CREATE_FAILURE,
	EQUIVALENCE_REMOVE_BEGIN,
	EQUIVALENCE_REMOVE_SUCCESS,
	EQUIVALENCE_REMOVE_FAILURE,
	EQUIVALENCE_LIST_BEGIN,
	EQUIVALENCE_LIST_SUCCESS,
	EQUIVALENCE_LIST_FAILURE,
	EQUIVALENCE_EDIT_BEGIN,
	EQUIVALENCE_EDIT_SUCCESS,
	EQUIVALENCE_EDIT_FAILURE,
} from "../types/EquivalenceTypes";

const initialState = {
	loading: false,
	equivalence: null,
	equivalences: null,
	error: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case EQUIVALENCE_LIST_BEGIN:
		case EQUIVALENCE_CREATE_BEGIN:
		case EQUIVALENCE_REMOVE_BEGIN:
		case EQUIVALENCE_EDIT_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
			};
		case EQUIVALENCE_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				equivalences: action.payload,
			};
		case EQUIVALENCE_CREATE_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				equivalence: action.payload,
			};
		case EQUIVALENCE_REMOVE_SUCCESS:
			const newEquivalencesList = state.equivalences["hydra:member"].filter(
				(equivalence) => equivalence["@id"] !== action.payload
			);

			return {
				...state,
				loading: false,
				error: null,
				equivalence: null,
				equivalences: {
					...state.equivalences,
					["hydra:member"]: newEquivalencesList,
				},
			};
		case EQUIVALENCE_EDIT_SUCCESS:
			const arrayOfEquivalences = [];

			state.equivalences["hydra:member"].map((item) => {
				if (item["@id"] === action.equivalence["@id"]) {
					arrayOfEquivalences.push(action.payload);
				} else {
					arrayOfEquivalences.push(item);
				}
			});

			return {
				...state,
				loading: false,
				error: null,
				equivalences: {
					...state.equivalences,
					["hydra:member"]: arrayOfEquivalences,
				},
			};
		case EQUIVALENCE_CREATE_FAILURE:
		case EQUIVALENCE_LIST_FAILURE:
		case EQUIVALENCE_REMOVE_FAILURE:
		case EQUIVALENCE_EDIT_FAILURE:
			return {
				...state,
				loading: false,
				error: true,
			};
		default:
			return state;
	}
};
