// List
export const USER_LIST_BEGIN = "USER_LIST_BEGIN";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAILURE = "USER_LIST_FAILURE";

// Load
export const USER_LOAD_BEGIN = "USER_LOAD_BEGIN";
export const USER_LOAD_SUCCESS = "USER_LOAD_SUCCESS";
export const USER_LOAD_FAILURE = "USER_LOAD_FAILURE";

// Edit
export const USER_EDIT_BEGIN = "USER_EDIT_BEGIN";
export const USER_EDIT_SUCCESS = "USER_EDIT_SUCCESS";
export const USER_EDIT_FAILURE = "USER_EDIT_FAILURE";

// Remove
export const USER_REMOVE_BEGIN = "USER_REMOVE_BEGIN";
export const USER_REMOVE_SUCCESS = "USER_REMOVE_SUCCESS";
export const USER_REMOVE_FAILURE = "USER_REMOVE_FAILURE";
