import {
	AUTH_BEGIN,
	AUTH_SUCCESS,
	AUTH_FAILURE,
	LOGOUT_BEGIN,
	LOGOUT_SUCCESS,
	PROFILE_EDIT_BEGIN,
	PROFILE_EDIT_SUCCESS,
	PROFILE_EDIT_FAILURE,
	SECURITY_EDIT_BEGIN,
	SECURITY_EDIT_SUCCESS,
	SECURITY_EDIT_FAILURE,
} from "../types/AuthTypes";

import DecodeToken from "jwt-decode";

const initialState = {
	isAuthenticated: false,
	loading: false,
	error: null,
	user: null,
	token: null,
	refreshToken: null,
	users: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case AUTH_BEGIN:
		case PROFILE_EDIT_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
			};
		case PROFILE_EDIT_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				user: {
					roles: action.payload.roles,
					username: action.payload.username,
					email: action.payload.email,
					firstName: action.payload.firstName,
					lastName: action.payload.lastName,
					avatar:
						action.payload.avatar !== undefined
							? action.payload.avatar
							: undefined,
				},
			};
		case AUTH_SUCCESS:
			const payload = DecodeToken(action.payload.token);

			window.localStorage.setItem(
				"refresh_token",
				action.payload.refresh_token
			);
			window.localStorage.setItem("token", action.payload.token);

			return {
				...state,
				loading: false,
				isAuthenticated: true,
				user: {
					roles: payload.roles,
					username: payload.username,
					email: payload.email,
					firstName: payload.firstName,
					lastName: payload.lastName,
					avatar: payload.avatar,
				},
				token: action.payload.token,
				refreshToken: action.payload.refresh_token,
			};
		case LOGOUT_SUCCESS:
		case AUTH_FAILURE:
			window.localStorage.removeItem("token");
			window.localStorage.removeItem("refresh_token");

			return {
				...state,
				isAuthenticated: false,
				loading: false,
				error: "security.login.form.error.bad_credentials",
				token: null,
				refreshToken: null,
				user: null,
			};
		case PROFILE_EDIT_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload["hydra:description"],
			};
		default:
			return state;
	}
};
