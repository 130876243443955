// Create Theme
export const IMAGE_BANK_CREATE_THEME_BEGIN = "IMAGE_BANK_CREATE_THEME_BEGIN";
export const IMAGE_BANK_CREATE_THEME_SUCCESS =
	"IMAGE_BANK_CREATE_THEME_SUCCESS";
export const IMAGE_BANK_CREATE_THEME_FAILURE =
	"IMAGE_BANK_CREATE_THEME_FAILURE";

// Load Theme
export const IMAGE_BANK_LOAD_THEME_BEGIN = "IMAGE_BANK_LOAD_THEME_BEGIN";
export const IMAGE_BANK_LOAD_THEME_SUCCESS = "IMAGE_BANK_LOAD_THEME_SUCCESS";
export const IMAGE_BANK_LOAD_THEME_FAILURE = "IMAGE_BANK_LOAD_THEME_FAILURE";

// Edit Theme
export const IMAGE_BANK_EDIT_THEME_BEGIN = "IMAGE_BANK_EDIT_THEME_BEGIN";
export const IMAGE_BANK_EDIT_THEME_SUCCESS = "IMAGE_BANK_EDIT_THEME_SUCCESS";
export const IMAGE_BANK_EDIT_THEME_FAILURE = "IMAGE_BANK_EDIT_THEME_FAILURE";

// List Theme
export const IMAGE_BANK_LIST_THEMES_BEGIN = "IMAGE_BANK_LIST_THEMES_BEGIN";
export const IMAGE_BANK_LIST_THEMES_SUCCESS = "IMAGE_BANK_LIST_THEMES_SUCCESS";
export const IMAGE_BANK_LIST_THEMES_FAILURE = "IMAGE_BANK_LIST_THEMES_FAILURE";

// Delete Theme
export const IMAGE_BANK_DELETE_THEME_BEGIN = "IMAGE_BANK_DELETE_THEME_BEGIN";
export const IMAGE_BANK_DELETE_THEME_SUCCESS =
	"IMAGE_BANK_DELETE_THEME_SUCCESS";
export const IMAGE_BANK_DELETE_THEME_FAILURE =
	"IMAGE_BANK_DELETE_THEME_FAILURE";
