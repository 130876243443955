import React from "react";
import {
	NavItem,
	NavLink,
	UncontrolledDropdown,
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
	Media,
	Badge,
} from "reactstrap";

// SVG
import DefaultAvatar from "../../../Assets/img/svg/avatar.svg";

import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "axios";
import * as Icon from "react-feather";
import classnames from "classnames";
import { history } from "../../../history";
import { useTranslation } from "react-i18next";
import { useRouteTranslation } from "../../../Utility/useRouteTranslation";

import { asset } from "../../../Utility/Asset";

const UserDropdown = (props) => {
	const { t } = useTranslation();
	const { tr } = useRouteTranslation();

	return (
		<DropdownMenu right>
			<DropdownItem href={tr("account.edit")}>
				<Icon.Settings size={14} className='mr-50' />
				<span className='align-middle'>{t("action.edit_profile")}</span>
			</DropdownItem>
			{/* <DropdownItem tag='a' href='#'>
				<Icon.Mail size={14} className='mr-50' />
				<span className='align-middle'>My Inbox</span>
			</DropdownItem>
			<DropdownItem tag='a' href='#'>
				<Icon.CheckSquare size={14} className='mr-50' />
				<span className='align-middle'>Tasks</span>
			</DropdownItem>
			<DropdownItem tag='a' href='#'>
				<Icon.MessageSquare size={14} className='mr-50' />
				<span className='align-middle'>Chats</span>
			</DropdownItem>
			<DropdownItem tag='a' href='#'>
				<Icon.Heart size={14} className='mr-50' />
				<span className='align-middle'>WishList</span>
			</DropdownItem> */}
			<DropdownItem divider />
			<DropdownItem
				tag='a'
				href='#'
				onClick={(e) => history.push(tr("logout"))}
			>
				<Icon.Power size={14} className='mr-50' />
				<span className='align-middle'>{t("action.logout")}</span>
			</DropdownItem>
		</DropdownMenu>
	);
};

class NavbarUser extends React.PureComponent {
	state = {
		navbarSearch: false,
		suggestions: [],
	};

	// componentDidMount() {
	// 	axios.get("/api/main-search/data").then(({ data }) => {
	// 		this.setState({ suggestions: data.searchResult });
	// 	});
	// }

	// handleNavbarSearch = () => {
	// 	this.setState({
	// 		navbarSearch: !this.state.navbarSearch,
	// 	});
	// };

	render() {
		return (
			<ul className='nav navbar-nav navbar-nav-user float-right'>
				{/* <NavItem className='nav-search' onClick={this.handleNavbarSearch}>
					<NavLink className='nav-link-search'>
						<Icon.Search size={21} data-tour='search' />
					</NavLink>
					<div
						className={classnames("search-input", {
							open: this.state.navbarSearch,
							"d-none": this.state.navbarSearch === false,
						})}
					>
						<div className='search-input-icon'>
							<Icon.Search size={17} className='primary' />
						</div>

						<div className='search-input-close'>
							<Icon.X
								size={24}
								onClick={(e) => {
									e.stopPropagation();
									this.setState({
										navbarSearch: false,
									});
									this.props.handleAppOverlay("");
								}}
							/>
						</div>
					</div>
				</NavItem> */}
				<UncontrolledDropdown
					tag='li'
					className='dropdown-notification nav-item'
				>
					<DropdownMenu tag='ul' right className='dropdown-menu-media'>
						<li className='dropdown-menu-header'>
							<div className='dropdown-header mt-0'>
								<h3 className='text-white'>5 New</h3>
								<span className='notification-title'>App Notifications</span>
							</div>
						</li>
						<PerfectScrollbar
							className='media-list overflow-hidden position-relative'
							options={{
								wheelPropagation: false,
							}}
						>
							<div className='d-flex justify-content-between'>
								<Media className='d-flex align-items-start'>
									<Media left href='#'>
										<Icon.PlusSquare
											className='font-medium-5 primary'
											size={21}
										/>
									</Media>
									<Media body>
										<Media heading className='primary media-heading' tag='h6'>
											You have new order!
										</Media>
										<p className='notification-text'>
											Are your going to meet me tonight?
										</p>
									</Media>
									<small>
										<time
											className='media-meta'
											dateTime='2015-06-11T18:29:20+08:00'
										>
											9 hours ago
										</time>
									</small>
								</Media>
							</div>
							<div className='d-flex justify-content-between'>
								<Media className='d-flex align-items-start'>
									<Media left href='#'>
										<Icon.DownloadCloud
											className='font-medium-5 success'
											size={21}
										/>
									</Media>
									<Media body>
										<Media heading className='success media-heading' tag='h6'>
											99% Server load
										</Media>
										<p className='notification-text'>
											You got new order of goods?
										</p>
									</Media>
									<small>
										<time
											className='media-meta'
											dateTime='2015-06-11T18:29:20+08:00'
										>
											5 hours ago
										</time>
									</small>
								</Media>
							</div>
							<div className='d-flex justify-content-between'>
								<Media className='d-flex align-items-start'>
									<Media left href='#'>
										<Icon.AlertTriangle
											className='font-medium-5 danger'
											size={21}
										/>
									</Media>
									<Media body>
										<Media heading className='danger media-heading' tag='h6'>
											Warning Notification
										</Media>
										<p className='notification-text'>
											Server has used 99% of CPU
										</p>
									</Media>
									<small>
										<time
											className='media-meta'
											dateTime='2015-06-11T18:29:20+08:00'
										>
											Today
										</time>
									</small>
								</Media>
							</div>
							<div className='d-flex justify-content-between'>
								<Media className='d-flex align-items-start'>
									<Media left href='#'>
										<Icon.CheckCircle
											className='font-medium-5 info'
											size={21}
										/>
									</Media>
									<Media body>
										<Media heading className='info media-heading' tag='h6'>
											Complete the task
										</Media>
										<p className='notification-text'>
											One of your task is pending.
										</p>
									</Media>
									<small>
										<time
											className='media-meta'
											dateTime='2015-06-11T18:29:20+08:00'
										>
											Last week
										</time>
									</small>
								</Media>
							</div>
							<div className='d-flex justify-content-between'>
								<Media className='d-flex align-items-start'>
									<Media left href='#'>
										<Icon.File className='font-medium-5 warning' size={21} />
									</Media>
									<Media body>
										<Media heading className='warning media-heading' tag='h6'>
											Generate monthly report
										</Media>
										<p className='notification-text'>
											Reminder to generate monthly report
										</p>
									</Media>
									<small>
										<time
											className='media-meta'
											dateTime='2015-06-11T18:29:20+08:00'
										>
											Last month
										</time>
									</small>
								</Media>
							</div>
						</PerfectScrollbar>
						<li className='dropdown-menu-footer'>
							<DropdownItem tag='a' className='p-1 text-center'>
								<span className='align-middle'>Read all notifications</span>
							</DropdownItem>
						</li>
					</DropdownMenu>
				</UncontrolledDropdown>
				<UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
					<DropdownToggle tag='a' className='nav-link dropdown-user-link'>
						<div className='user-nav d-sm-flex d-none'>
							<span className='user-name text-bold-600'>
								{this.props.currentUser.firstName.charAt(0).toUpperCase() +
									this.props.currentUser.firstName.slice(1) +
									" " +
									this.props.currentUser.lastName.charAt(0).toUpperCase() +
									"."}
							</span>
							<span className='user-status'>
								@{this.props.currentUser.username}
							</span>
						</div>
						<span data-tour='user'>
							<img
								src={
									this.props.currentUser.avatar !== undefined
										? asset(this.props.currentUser.avatar.slug, "avatar")
										: DefaultAvatar
								}
								className='round'
								height='40'
								width='40'
								alt='avatar'
							/>
						</span>
					</DropdownToggle>
					<UserDropdown {...this.props} />
				</UncontrolledDropdown>
			</ul>
		);
	}
}
export default NavbarUser;
