import {
	USER_LIST_BEGIN,
	USER_LIST_SUCCESS,
	USER_LIST_FAILURE,
	USER_LOAD_BEGIN,
	USER_LOAD_SUCCESS,
	USER_LOAD_FAILURE,
	USER_EDIT_BEGIN,
	USER_EDIT_SUCCESS,
	USER_EDIT_FAILURE,
	USER_REMOVE_BEGIN,
	USER_REMOVE_SUCCESS,
	USER_REMOVE_FAILURE,
} from "../types/AdminUserTypes";

const initialState = {
	loading: false,
	error: null,
	users: null,
	user: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case USER_LIST_BEGIN:
		case USER_LOAD_BEGIN:
		case USER_EDIT_BEGIN:
		case USER_REMOVE_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
				user: null,
			};
		case USER_LOAD_SUCCESS:
			return {
				...state,
				loading: false,
				user: action.payload,
			};
		case USER_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				users: action.payload,
			};
		case USER_EDIT_SUCCESS:
			return {
				...state,
				loading: false,
				user: action.payload,
			};

		case USER_REMOVE_SUCCESS:
			const newUserList = state.users["hydra:member"].filter(
				(user) => user["@id"] !== action.payload
			);

			return {
				...state,
				loading: false,
				error: null,
				users: {
					...state.users,
					["hydra:member"]: newUserList,
				},
			};
		case USER_LIST_FAILURE:
		case USER_LOAD_FAILURE:
		case USER_EDIT_FAILURE:
		case USER_REMOVE_FAILURE:
			return {
				...state,
				loading: false,
				error: true,
			};
		default:
			return state;
	}
};
