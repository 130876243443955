// List
export const STATUS_LIST_BEGIN = "STATUS_LIST_BEGIN";
export const STATUS_LIST_SUCCESS = "STATUS_LIST_SUCCESS";
export const STATUS_LIST_FAILURE = "STATUS_LIST_FAILURE";

// Create
export const STATUS_CREATE_BEGIN = "STATUS_CREATE_BEGIN";
export const STATUS_CREATE_SUCCESS = "STATUS_CREATE_SUCCESS";
export const STATUS_CREATE_FAILURE = "STATUS_CREATE_FAILURE";

// Edit
export const STATUS_EDIT_BEGIN = "STATUS_EDIT_BEGIN";
export const STATUS_EDIT_SUCCESS = "STATUS_EDIT_SUCCESS";
export const STATUS_EDIT_FAILURE = "STATUS_EDIT_FAILURE";

// Remove
export const STATUS_REMOVE_BEGIN = "STATUS_REMOVE_BEGIN";
export const STATUS_REMOVE_SUCCESS = "STATUS_REMOVE_SUCCESS";
export const STATUS_REMOVE_FAILURE = "STATUS_REMOVE_FAILURE";

///// ----------------------------------------------- ///////

// List StatusInfo
export const STATUS_LIST_INFO_BEGIN = "STATUS_LIST_INFO_BEGIN";
export const STATUS_LIST_INFO_SUCCESS = "STATUS_LIST_INFO_SUCCESS";
export const STATUS_LIST_INFO_FAILURE = "STATUS_LIST_INFO_FAILURE";

// Create StatusInfo
export const STATUS_INFO_CREATE_BEGIN = "STATUS_INFO_CREATE_BEGIN";
export const STATUS_INFO_CREATE_SUCCESS = "STATUS_INFO_CREATE_SUCCESS";
export const STATUS_INFO_CREATE_FAILURE = "STATUS_INFO_CREATE_FAILURE";

// Edit StatusInfo
export const STATUS_INFO_EDIT_BEGIN = "STATUS_INFO_EDIT_BEGIN";
export const STATUS_INFO_EDIT_SUCCESS = "STATUS_INFO_EDIT_SUCCESS";
export const STATUS_INFO_EDIT_FAILURE = "STATUS_INFO_EDIT_FAILURE";

// Remove StatusInfo
export const STATUS_INFO_REMOVE_BEGIN = "STATUS_INFO_REMOVE_BEGIN";
export const STATUS_INFO_REMOVE_SUCCESS = "STATUS_INFO_REMOVE_SUCCESS";
export const STATUS_INFO_REMOVE_FAILURE = "STATUS_INFO_REMOVE_FAILURE";
