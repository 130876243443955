// Create
export const EDUCATION_LEVEL_CREATE_BEGIN = "EDUCATION_LEVEL_CREATE_BEGIN";
export const EDUCATION_LEVEL_CREATE_SUCCESS = "EDUCATION_LEVEL_CREATE_SUCCESS";
export const EDUCATION_LEVEL_CREATE_FAILURE = "EDUCATION_LEVEL_CREATE_FAILURE";

// Load
export const EDUCATION_LEVEL_LOAD_BEGIN = "EDUCATION_LEVEL_LOAD_BEGIN";
export const EDUCATION_LEVEL_LOAD_SUCCESS = "EDUCATION_LEVEL_LOAD_SUCCESS";
export const EDUCATION_LEVEL_LOAD_FAILURE = "EDUCATION_LEVEL_LOAD_FAILURE";

// List
export const EDUCATION_LEVEL_LIST_BEGIN = "EDUCATION_LEVEL_LIST_BEGIN";
export const EDUCATION_LEVEL_LIST_SUCCESS = "EDUCATION_LEVEL_LIST_SUCCESS";
export const EDUCATION_LEVEL_LIST_FAILURE = "EDUCATION_LEVEL_LIST_FAILURE";

// Remove
export const EDUCATION_LEVEL_REMOVE_BEGIN = "EDUCATION_LEVEL_REMOVE_BEGIN";
export const EDUCATION_LEVEL_REMOVE_SUCCESS = "EDUCATION_LEVEL_REMOVE_SUCCESS";
export const EDUCATION_LEVEL_REMOVE_FAILURE = "EDUCATION_LEVEL_REMOVE_FAILURE";

// Edit
export const EDUCATION_LEVEL_EDIT_BEGIN = "EDUCATION_LEVEL_EDIT_BEGIN";
export const EDUCATION_LEVEL_EDIT_SUCCESS = "EDUCATION_LEVEL_EDIT_SUCCESS";
export const EDUCATION_LEVEL_EDIT_FAILURE = "EDUCATION_LEVEL_EDIT_FAILURE";
