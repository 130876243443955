import "./i18n";
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Layout } from "./Utility/context/Layout";
import * as serviceWorker from "./serviceWorker";
import Store from "./Redux/store";
import Spinner from "./Components/Theme/spinner/Fallback-spinner";
import "./index.scss";

const LazyApp = lazy(() => import("./App"));

// configureDatabase()
ReactDOM.render(
	<Provider store={Store}>
		<Suspense fallback={<Spinner />}>
			<Layout>
				<LazyApp />
			</Layout>
		</Suspense>
	</Provider>,
	document.getElementById("app")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
